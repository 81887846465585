// JavaScript Document

// Scripts written by Jordan @ Weblinx, Inc.

// vendor

import jQuery from "jquery";
import "slick-carousel";

// custom


jQuery(document).ready(function () {


    jQuery(".banner-main").slick({
        dots: false,
        infinite: true,
        arrows:false,
        speed: 600,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 8000
    });

    jQuery(".testimonial-cus-main").slick({
        dots: false,
        infinite: true,
        arrows:true,
        speed: 600,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 8000
    });

    // accordian part

    jQuery(".accordian-title").on("click", function (j) {
        const dropDown = jQuery(this).closest(".accordian-box").find(".accordian-desc");
        jQuery(this).closest(".accordian-item").find(".accordian-desc").not(dropDown).slideUp();

        if (jQuery(this).hasClass("active")) {
            jQuery(this).removeClass("active");
        } else {
            jQuery(this).closest(".accordian-item").find(".accordian-title.active").removeClass("active");
            jQuery(this).addClass("active");
        }

        dropDown.stop(false, true).slideToggle();
        j.preventDefault();
    });
});

